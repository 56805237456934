<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card title="Information" class="mb-5">
      <div>
          <vs-button style='float: right' type='filled' color='primary' :to='{name:"contracts-edit", params:{id:$route.params.id}}'>Edit</vs-button>
      </div>
        <div class="flex">

          <table class="info sm:w-full md:w-1/2">
            <tr>
              <td class="font-semibold">Contract Code</td>
              <td>{{ dt.code }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Contract Name</td>
              <td>{{dt.name}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Customer Name</td>
              <td>{{dt.customer_name}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Period</td>
              <td>{{dt.date_start}} until {{dt.date_start}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Status</td>
              <td>{{dt.is_active ? 'Active' : 'Inactive'}}</td>
            </tr>
            <tr>
              <td class="font-semibold">Description</td>
              <td>{{dt.description}}</td>
            </tr>
          </table>
          
        </div>
      </vx-card>
      
    </div>
    <div class="vx-col w-full mb-base">
        <price-lists :contract_id='$route.params.id' />
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import PriceLists from '@/views/pages/marketing/price_lists/Index.vue'

export default {
  components:{
    PriceLists
  },
  data(){
    return {
      dt: {},
      details: [],
      cost_lists: [],
      idDeleteCost: null,
      idApproveCost: null,
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'contracts/show',
      dispatchShowCosts: 'contracts/get_costs'
    }),
    async getData(){
      let { data } = await this.dispatchShow(this.$route.params.id)
      this.dt = data
    },
    async getCosts(){
      let { data } = await this.dispatchShowCosts(this.$route.params.id)
      this.cost_lists = data
    },
    approveCost(id){
      this.idApproveCost = id
      this.$vs.dialog({
        type: `confirm`,
        color: `primary`,
        title: `Are you sure ?`,
        text: `This cost will be approved.`,
        accept: this.confirmApproveCost
      })
    },
    async confirmApproveCost(){
      try {
        await this.$store.dispatch('contracts/approve_costs', this.idApproveCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been approved successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deleteCost(id){
      this.idDeleteCost = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDeleteCost
      })
    },
    async confirmDeleteCost(){
      try {
        await this.$store.dispatch('contracts/delete_costs', this.idDeleteCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
  },
  mounted(){
    this.getData()
    this.getCosts()
  }
}
</script>

<style>

</style>